import React, { useCallback, useId } from 'react';
import { t } from "i18next";
//import {  useNavigate } from 'react-router-dom';
import { ToastIntent, Toaster, makeStyles, useToastController } from '@fluentui/react-components';
import { getToastMessageComponent } from '../../common/components/ToastMessage';
//import { getEnvironment360URL } from '../../services/configs/appConfig';
import SpinnerComponent from '../../common/components/controls/spinner';
import { getfileNameFromUrl, uploadFileAndGetToken } from './fileActionUtils';
import { getEnvironment360URL } from '../../services/configs/appConfig';
import { useNavigate } from 'react-router-dom';


interface IPrerequistic{
    fileId: number | null;
    serverName: string | null;
     fileName: string;
};
const useStyles = makeStyles({
    spinnerStyles:
     {
        paddingTop: "30vh"
     },
  });
const ArchiveFile: React.FC = () => {
    const loadingText = t("Archiving files to 360...");
    const [showSpinner, setShowSpinner] = React.useState(false);
    const toasterId = useId();
    const { dispatchToast ,dismissToast } = useToastController(toasterId);
    //const [prereqData, setPrereqData] = React.useState<IPrerequistic | null>(null);
    const navigate = useNavigate();
   // const navigate = useNavigate();
    const showToast = (message:string ,toastType:ToastIntent)=>{
        dispatchToast(
            getToastMessageComponent(message,archiveTheFile),
            { timeout:-1, intent: toastType }
          );
    };

   /*  const openOfficeDialogToArchive = (resData: string)=>{
        Office.context.ui.displayDialogAsync(window.location.origin + "/dialogrediect.html?docno="
            + encodeURIComponent(resData) + "&public360Url=" + encodeURIComponent(getEnvironment360URL()), {height: 70, width: 52, promptBeforeOpen: false},
            function (asyncResult) {
            if (asyncResult.status === Office.AsyncResultStatus.Failed) {
                setShowSpinner(false);
                showToast(t("Failed to open dialog: ") + asyncResult.error.message ,"error");
            } else {
                const dialog = asyncResult.value;

                // Add event handler for when the dialog is closed
                dialog.addEventHandler(Office.EventType.DialogEventReceived,  ()=> {
                    onDialogClosed(resData);
                });
            }
            }
        );
    }; */
    const openOfficeDialogToArchive = (resData: string)=>{
        Office.context.ui.displayDialogAsync(window.location.origin + "/dialogrediect.html?docno="
            + encodeURIComponent(resData) + "&public360Url=" + encodeURIComponent(getEnvironment360URL()), {height: 70, width: 52, promptBeforeOpen: false},
            function (asyncResult) {
            if (asyncResult.status === Office.AsyncResultStatus.Failed) {
                setShowSpinner(false);
                showToast(t("Failed to open dialog: ") + asyncResult.error.message ,"error");
            } else {
                const dialog = asyncResult.value;

                // Add event handler for when the dialog is closed
                dialog.addEventHandler(Office.EventType.DialogEventReceived,  ()=> {
                    onDialogClosed(resData);
                });
            }
            }
        );
    };
    function onDialogClosed(docRecno: string) {
        setShowSpinner(false);
        navigate(`/archivehome?docRecno=${encodeURIComponent(docRecno)}`);
    }
   const archiveTheFile  = ()=> {
    dismissToast(toasterId);
    setShowSpinner(true);
    const prereqData =   getPrerequisiteData();
    let checkWindowClosed: NodeJS.Timeout;
    try {
        if(prereqData){
            uploadFileAndGetToken(prereqData.fileName, async (resData : string | null) => {
                console.log(resData);
                if(resData){
                    openOfficeDialogToArchive(resData);
                }

             /*    archiveFile(resData,(res: string | null) => {
                    if (res === null) {
                        showToast(t("Recno not generated"),"error");
                        setShowSpinner(false);
                    } else {
                        openOfficeDialogToArchive(res);
                }
            }, (err: string) => {
                 setShowSpinner(false);
                 showToast(err,"error");
                });
     */
            },(err: unknown)=>{
                console.log(err);
            });
        }


    }
    catch (exception) {
        setShowSpinner(false);
        showToast(exception as string,"error");
    }

    return () => clearInterval(checkWindowClosed);
   };

   const getPrerequisiteData = useCallback( () => {

    if (Office.context.document && Office.context.document.url) {
        const docUrl = Office.context.document.url;
        const fileName = getfileNameFromUrl(docUrl);
        const wordArray = fileName ? fileName.split(/[\s]+/) : [];
        const lastWord = wordArray.pop();
        const fileId = lastWord ? lastWord.split("_")[0] : null;

        const regxResult = new RegExp('(@.*?/)').exec(docUrl);
        const serverName = (regxResult && regxResult.length) ? regxResult[0].replace(/^.|.$/g, '') : null;
        //docUrl.split("@")[1] ? docUrl.split("@")[1].split("/")[0] : null;
        const prereqData = {
            fileId: isNaN(Number(fileId)) ? null : Number(fileId),
            serverName: serverName,
            fileName: fileName ? fileName : ""
        };
               return prereqData;
            }else{
                return null;
            }

}, []);

    React.useEffect(() => {

        archiveTheFile();
    },[]);

   /*  function onDialogClosed(docRecno: string) {
        setShowSpinner(false);
        navigate(`/archivefile?docRecno=${encodeURIComponent(docRecno)}`);
    } */

    return (
        <>
            <Toaster toasterId={toasterId} />
            <SpinnerComponent showSpinner={showSpinner} size='medium' label={loadingText} labelPosition='after' spinnerStyle={useStyles().spinnerStyles}/>
        </>
    );
};

export default ArchiveFile;
