export enum MAIL_STATUS {
    EXISTS = "Exists",
    NOT_EXISTS = "NotExists",
    LOADING = "Loading",
    ERROR = "Error"
}
export enum FILE_STATUS {
    EXISTS = "Exists",
    NOT_EXISTS = "NotExists",
    LOADING = "Loading",
    ERROR = "Error"
}
export interface IMailInfo {
    restUrl: string;
    serviceToken: string;
    itemId: string;
    docRecno: string | null;
}

export interface IDocumentDetails {
    AccessCodeDesc: string;
    CaseDescription: string;
    ArchiveCode: string;
    CaseNumber: string;
    CategoryCode: string;
    DocumentDetailsLink: string;
    DocumentTitle: string;
    Documentnumber: string;
    OrgUnitName: string;
    Paragraph: string;
    ResponsibleName: string;
}
export interface IDocumentDetailsFile {
    FileName: string;
    DocumentRecno: string;
    DocumentNumber: string;
    FileType: string;
    DocumentTitle: string;
    DocumentCategory: string;
    ArchiveCode: string;
    ResponsibleName: string;
    OrgUnitName: string;
    AccessCodeDesc: string;
    Paragraph: string;
    DocumentDetailsLink: string;
    DocumentStatus: string;
    IsCorrespondence: boolean;
    //FileFormat: FILE_FORMAT;
    CaseNumber :string;
    CaseDescription : string;
}
export interface IConfigInfo {
    VersionOf360ForHelp : string;
}