import { UploadStreamAsync, upload, uploadAndGetRecno } from "../../services/api/dataServiceOffice";

export const getFiledata = (sucCb, errCb) => {
    const getAllSlices = (file, sucCb, errcb) => {
        const sliceCount = file.sliceCount;
        let sliceIndex = 0;
        let docdata = [];
        const getSlice = function () {
            file.getSliceAsync(sliceIndex, function (asyncResult) {
                if (asyncResult.status === Office.AsyncResultStatus.Succeeded) {
                    docdata = docdata.concat(asyncResult.value.data);
                    sliceIndex++;
                    if (sliceIndex === sliceCount) {
                        file.closeAsync();
                        sucCb(docdata);
                    }
                    else {
                        getSlice();
                    }
                }
                else {
                    file.closeAsync();
                    errCb(asyncResult.error.message);
                }
            });
        };
        getSlice();
    };
    try {
        Office.context.document.getFileAsync(Office.FileType.Compressed, function (asyncResult) { //{ sliceSize: 10240 },
            if (asyncResult.status === Office.AsyncResultStatus.Failed) {
                errCb(asyncResult.error.message);
            }
            else {
                getAllSlices(asyncResult.value, sucCb, errCb);
            }

        });
    } catch (e) {
        errCb(e);
    }
};
export const uploadFileAndGetToken = (filename: string,
    successCb: { (resData: string | null): Promise<void>; (arg0: string): void; }, errorCb: (err: unknown) => void) => {
    getFiledata( (fileArray) => {
        upload(new Uint8Array(fileArray), filename,
    (res:string)=>{
        uploadAndGetRecno(res, filename,successCb);
    });
       // uploadAndGetRecno(new Uint8Array(fileArray), filename,successCb);
       // UploadStreamAsync(new Blob(fileArray), filename,successCb);

    }, errorCb);
};

export const getfileNameFromUrl = (url: string): string | undefined => {
    if (Office.context.platform === Office.PlatformType.OfficeOnline) {
        return url.split("/").pop();
    } else {
        if (url.includes('https')) {
            return url.split("/").pop();
        }
        return url.substr(url.lastIndexOf('\\') + 1);
    }
};