import { IDocumentDetails, IDocumentDetailsFile } from "../../../@types/models";
import ArchiveDetailFile from "../archiveDetail/arcchiveDetailOffice";
import ArchiveDetail from "../archiveDetail/archiveDetail";
import ArchiveHome from "../archiveHome/archiveHome";

export const getArchiveHome = (): JSX.Element => {
    return (
        <ArchiveHome/>
    );
};
export const getDocumentDetailComp = (documentInfo: IDocumentDetails): JSX.Element => {
    return (
        <ArchiveDetail
            AccessCodeDesc={documentInfo.AccessCodeDesc}
            CaseDescription={documentInfo.CaseDescription}
            ArchiveCode={documentInfo.ArchiveCode}
            CaseNumber={documentInfo.CaseNumber}
            CategoryCode={documentInfo.CategoryCode}
            DocumentDetailsLink={documentInfo.DocumentDetailsLink}
            DocumentTitle={documentInfo.DocumentTitle}
            Documentnumber={documentInfo.Documentnumber}
            OrgUnitName={documentInfo.OrgUnitName}
            Paragraph={documentInfo.Paragraph}
            ResponsibleName={documentInfo.ResponsibleName}
        />
    );
};

export const getDocumentDetailFile= (documentInfo: IDocumentDetailsFile): JSX.Element => {
    return (
        <ArchiveDetailFile
        FileName={documentInfo.FileName}
        FileType={documentInfo.FileType}
       // FileFormat={documentInfo.FileFormat}
        CaseNumber={documentInfo.CaseNumber}
        CaseDescription={documentInfo.CaseDescription}
        DocumentRecno={documentInfo.DocumentRecno}
        DocumentNumber={documentInfo.DocumentNumber}
        DocumentTitle={documentInfo.DocumentTitle}
        DocumentCategory={documentInfo.DocumentCategory}
        ArchiveCode={documentInfo.ArchiveCode}
        OrgUnitName={documentInfo.OrgUnitName}
        ResponsibleName={documentInfo.ResponsibleName}
        Paragraph={documentInfo.Paragraph}
        AccessCodeDesc={documentInfo.AccessCodeDesc}
        IsCorrespondence={documentInfo.IsCorrespondence}
        DocumentStatus={documentInfo.DocumentStatus}
        DocumentDetailsLink={window.location.origin + "/" + encodeURI(documentInfo.DocumentDetailsLink)}
        />
    );
};