import { IDocumentDetails, IDocumentDetailsFile } from "../../@types/models";
import { getEnvironment360BizURL, ApiConfig, getFetchApiOptions, getEnvironment360URL } from "../configs/appConfig";
import { getAccessToken } from "./dataService";
import fetchClient from "./fetchClient";

export const getProductName = async ( sCB :(res:string)=>void, eCB : (err : string)=>void) => {
    try {
        const token = await getAccessToken();
        const response = await fetchClient(getEnvironment360BizURL() +
            ApiConfig.getProductName, {
                headers: {
                    "Accept": "application/json",
                    "Accept-Language": document.documentElement.lang,
                    "Authorization": `Bearer ${token}`,
                    "Content-Type": "application/json"
                },
                method: "POST",
                ...getFetchApiOptions
            });
        sCB(response as string);
        } catch (err) {
            eCB("Error : "+ err);
        }
  };

  export const findAndGetFileInfo = async (filerecno:string, sCB :(res:IDocumentDetailsFile)=>void, eCB : (err : string)=>void) => {
    try {
        const token = await getAccessToken();
        const response :IDocumentDetailsFile = await fetchClient(getEnvironment360BizURL() +
            ApiConfig.GetArchivedFileInfo, {
                headers: {
                    "Accept": "application/json",
                    "Accept-Language": document.documentElement.lang,
                    "Authorization": `Bearer ${token}`,
                    "Content-Type": "application/json"
                },
                body:JSON.stringify({recno: Number(filerecno)}),
                method: "POST",
                ...getFetchApiOptions
            });
        sCB(response);
        } catch (err) {
            eCB("Error : "+ err);
        }
  };


  export const upload = async (data: Uint8Array, filename: string,sCB: (arg0: string) => void) => {


    try {
        const token = await getAccessToken();
        const response = await fetchClient(getEnvironment360URL() +
            ApiConfig.upload+ "?filename=" + encodeURIComponent(filename), {
                headers: {
                    "Accept": "application/json",
                    "Accept-Language": document.documentElement.lang,
                    "Authorization": `Bearer ${token}`,
                    "Content-Type": "application/octet-stream"
                },
                data:data,
                method: "POST",
                ...getFetchApiOptions
            },true);
        sCB(response as string);
        } catch (err) {
           console.log("Error : "+ err);
        }
};

export const UploadStreamAsync = async (data: Blob, filename: string,sCB: (arg0: string) => void) => {


    try {
        const token = await getAccessToken();
        const response = await fetchClient(getEnvironment360BizURL() +
            "/SI.Biz.Core.OfficeAddin/SI.Biz.Core.OfficeAddin/OfficeService/UploadStreamAsync"+ "?filename=" + encodeURIComponent(filename), {
                headers: {
                    "Accept": "application/json",
                    "Accept-Language": document.documentElement.lang,
                    "Authorization": `Bearer ${token}`,
                    "Content-Type": "application/octet-stream"
                },
                data:data,
                method: "POST",
                ...getFetchApiOptions
            });
        sCB(response as string);
        } catch (err) {
           console.log("Error : "+ err);
        }
};

export const uploadAndGetRecno = async (filetoken: string, filename: string,sCB: (arg0: string) => void) => {


    try {
        const token = await getAccessToken();
        const response = await fetchClient(getEnvironment360BizURL() +
            ApiConfig.SaveFileFromOffice, {
                headers: {
                    "Accept": "application/json",
                    "Accept-Language": document.documentElement.lang,
                    "Authorization": `Bearer ${token}`,
                    "Content-Type": "application/json"
                },
                body:JSON.stringify({fileToken:filetoken, fileName:filename}),
                method: "POST",
                ...getFetchApiOptions
            });
        sCB(response as string);
        } catch (err) {
           console.log("Error : "+ err);
        }
};